<template>
    <layout-heading>
        <portal to="title">
            <img src="../assets/images/icon-QR.svg" alt="" />
            <span>bApps List</span>
        </portal>

        <div class="row align-items-center dumbapp-filter">
            <div class="col-md-6"></div>
            <div class="col-md-4">
                <input
                    class="form-control"
                    type="text"
                    v-model="filterValue"
                    placeholder="Filter"
                />
            </div>
            <div class="col-md-2">
                <a href="#" id="clear-filter" @click.prevent="clearFilter">Clear Filter</a>
            </div>
        </div>

        <div class="control-bar">
            <Pagination :pages="pages" :page="page" @update:page="setPage" />
            <div class="btn-group btn-group-toggle btn-group-sm">
                <button
                    class="btn btn-sm"
                    :class="display === 'table' ? 'btn-primary active' : 'btn-outline-primary'"
                    @click="setDisplay('table')"
                >
                    Table
                </button>
                <button
                    class="btn btn-sm"
                    :class="display === 'grid' ? 'btn-primary active' : 'btn-outline-primary'"
                    @click="setDisplay('grid')"
                >
                    Grid
                </button>
            </div>
        </div>

        <div v-if="display === 'table'" class="table-responsive">
            <table id="dumbapp-table" class="table table-sm table-sortable">
                <thead>
                    <tr>
                        <th @click="sort" data-field="shortcode">Shortcode</th>
                        <th @click="sort" data-field="steps.address.parameters.value">Contract</th>
                        <th @click="sort" data-field="steps.method">Function</th>
                        <th @click="sort" data-field="steps.method">Input</th>
                        <th @click="sort" class="down" data-field="meta.created">Date Created</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="it in list" :key="it.shortcode">
                        <td class="shortcode">
                            <a :href="it.deepLink" target="_blank">{{ it.shortcode }}</a>
                            <a :href="it.configLink" target="_blank"
                                ><i class="material-icons">settings_applications</i></a
                            >
                        </td>
                        <td class="contract-addresses ellipsize-address">
                            <div
                                v-for="(step, index) in it.steps"
                                :key="it.shortcode + '-' + index"
                            >
                                <template v-if="step.method && isLiteralSource(step.address)">
                                    <span class="contract-name-value"
                                        >{{ step.contractName }}
                                    </span>
                                    <span class="contract-name-address">
                                        <Clipboard
                                            :address="step.address.parameters.value"
                                            :network="step.network.parameters.value"
                                        />
                                    </span>
                                </template>
                                <template v-else-if="step.method">
                                    <span>Dynamic Contract</span>
                                </template>
                            </div>
                        </td>
                        <td class="method">
                            <template v-for="step in it.steps">
                                <div v-if="step.hasValues">
                                    <v-popover>
                                        <a
                                            href="javascript: void(0)"
                                            class="method tooltip-target"
                                            >{{ step.ether ? "Send Ether" : step.method }}</a
                                        >
                                        <template #popover>
                                            <div
                                                v-for="arg in step.arguments"
                                                v-if="arg.value && arg.value.type === 'literal'"
                                                class="data"
                                            >
                                                <span class="label">{{ arg.label }}</span>
                                                <span class="data-value">{{ arg.value.parameters.value }}</span>
                                            </div>
                                        </template>
                                    </v-popover>
                                </div>
                                <div v-else>{{ step.ether ? "Send Ether" : step.method }}</div>
                            </template>
                        </td>
                        <td class="inputs">{{ it.inputs }}</td>
                        <td class="created">
                            <div class="date-wrap">{{ it.created.format("ll") }}</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-else id="dumbapp-grid">
            <div v-for="it in list" :key="it.shortcode" class="dumbapp">
                <h4>{{ it.title || it.method || "-" }}</h4>
                <Markdown v-if="it.description" :content="it.description" />
                <a :href="it.url" target="_blank">{{ it.url }}</a>
            </div>
        </div>
        <Pagination :pages="this.pages" :page.sync="page" />
    </layout-heading>
</template>

<script>
import "bootstrap";
import Clipboard from "@/components/Clipboard";
import Markdown from "@/components/Markdown";
import Pagination from "@/components/Pagination";
import { debounce } from "@/lib/vutil";
import moment from "moment";
import { mapGetters } from "vuex";
import { isDumbappValue, isLiteralSource } from "@blockwell/dumbapp";

export default {
    components: { Markdown, Pagination, Clipboard },
    props: {
        query: Object,
    },
    data() {
        return {
            list: [],
            originalList: [],
            filterValue: "",
            pageSize: 50,
            count: 1,
        };
    },
    computed: {
        ...mapGetters("user", ["api"]),
        sorting() {
            return this.$route.query.sort || "-meta.created";
        },
        page() {
            let page = 1;
            let q = this.$route.query.page;
            if (q && parseInt(q) > 0) {
                page = parseInt(q);
            }
            return page;
        },
        display() {
            let dis = this.$route.query.display;
            if (dis) {
                return dis;
            }
            if (this.$route.hash === "#grid") {
                return "grid";
            }

            return "table";
        },
        filter() {
            return this.$route.query.filter;
        },
        pages() {
            return Math.ceil(this.count / this.pageSize);
        },
        searchQuery() {
            let q = {
                sort: this.sorting,
                pageSize: this.pageSize,
                text: this.filter,
                page: this.page,
            };
            let query = this.query;

            if (query.contract) {
                q.contractAddress = query.contract;
            }
            if (query.method) {
                q.method = query.method;
            }
            return q;
        },
    },
    methods: {
        clearFilter() {
            this.filterValue = "";
        },
        setSort(sort) {
            this.$router.replace({
                ...this.$route,
                query: {
                    ...this.$route.query,
                    sort
                }
            })
        },
        setDisplay(display) {
            this.$router.replace({
                ...this.$route,
                query: {
                    ...this.$route.query,
                    display
                }
            })
        },
        setPage(page) {
            this.$router.replace({
                ...this.$route,
                query: {
                    ...this.$route.query,
                    page
                }
            })
        },
        sort(e) {
            let $ele = $(e.target);
            let sort = $ele.data("field");

            if ($ele.hasClass("down")) {
                $("#dumbapp-table th").removeClass("up").removeClass("down");
                $ele.addClass("up");
                this.setSort(sort);
            } else {
                $("#dumbapp-table th").removeClass("up").removeClass("down");
                $ele.addClass("down");
                this.setSort("-" + sort);
            }
        },
        isDumbappValue,
        isLiteralSource
    },
    watch: {
        filterValue: {
            handler: debounce(function (val) {
                this.$router.replace({
                    ...this.$route,
                    query: {
                        ...this.$route.query,
                        filter: val || undefined
                    }
                })
            }),
        },
        searchQuery: {
            immediate: true,
            handler(val) {
                return this.api.getAllCodes(val).then((it) => {
                    this.count = it.count;
                    this.list = it.data.map((it) => {
                        let code = {
                            title: it.title,
                            description: it.description,
                            created: moment(it.meta.created),
                            shortcode: it.shortcode,
                            deepLink: "/" + it.shortcode,
                            configLink: "/code/" + it.shortcode,
                            url:
                                window.location.protocol +
                                "//" +
                                window.location.host +
                                "/" +
                                it.shortcode,
                        };
                        if (it.steps && it.steps.length > 0) {
                            code.method = it.steps[0].method;
                            code.steps = it.steps.map((step) => {
                                let hasValues = step.arguments.find((it) => !!it.value);
                                return {
                                    method: step.method,
                                    address: step.address,
                                    hasValues: !!hasValues,
                                    arguments: step.arguments,
                                    ether: step.ether,
                                    contractName: step.contractName,
                                    network: step.network,
                                };
                            });
                            code.address = it.steps[0].address.parameters?.value;
                            code.addresses = it.steps.map((step) => step.address?.parameters?.value);
                            code.inputs = it.dynamic.map((dyn) => dyn.label).join(", ");
                        }
                        return code;
                    });
                });
            },
        },
    },
    created() {
        if (this.query.display === "grid") {
            this.display = "grid";
        }
    },
};
</script>

<style lang="scss">
@import "~@/assets/css/custom";

#dumbapp-table {
    line-height: 27px;
}

#dumbapp-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    grid-gap: 15px;
    justify-content: space-around;

    .dumbapp {
        padding: 15px;
        @include mdElevation(2);

        h4 {
            font-size: 16px;
        }
    }

    margin-bottom: 30px;

    @media screen and (max-width: 690px) {
        display: block;
    }
}

.control-bar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 20px;

    .btn-group {
        margin-left: 20px;
    }

    @media screen and (max-width: 768px) {
        flex-direction: column-reverse;

        .btn-group {
            margin-left: 0;
            margin-bottom: 10px;
        }
    }

    @media screen and (max-width: 420px) {
        font-size: 0.8em;
    }
}
</style>
